import { Link } from 'react-router-dom'

import Sponsors from '../components/Sponsors';

import '../page.scss'
import './StartPage.scss';

import flowerImage from '../assets/blomma.png';

function StartPage() {
    return (
        <div className="StartPage">
            <div className="pageItem">
                <div className="pageItem-header">
                    <h2>KonstMajrundan Kristihimmelfärdshelgen 2025</h2>
                </div>
                <div className="pageItem-body news-body">
                    <p>
                        KonstMajrundan och Konstlandskapet Öland bjuder in till öppna ateljeer på Kristihimmelfärdshelgen 29, 30, 31 maj och 1a juni.
                    </p>
                    <p>
                        KonstMajrundans medlemar hittar du <Link to="/artists">här</Link>
                    </p>
                    <div><img className='flowerImage' src={flowerImage} alt='bild på blomma' /></div>
                    <div>KonstMajrundan är en del av Konstlandskapet Öland</div>
                </div>
            </div>
            <div className='pageItem'>
                <Sponsors />
            </div>
        </div>
    );
}

export default StartPage;
