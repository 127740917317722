import './SocialLink.scss';

import instagramLogo from '../assets/Instagram_Glyph_Black.svg';
import facebookLogo from '../assets/Facebook_Logo_Primary.png';

type socialType = 'instagram' | 'facebook';

type SocialLinkProps = {
    type: socialType;
    username: string;
};

export function SocialLink({ type, username }: SocialLinkProps) {
    const getLogoImage = () => {
        switch (type) {
            case 'instagram':
                return instagramLogo;
            case 'facebook':
                return facebookLogo;
            default:
                return undefined;
        }
    }

    const getSocialTypeName = () => {
        switch (type) {
            case 'instagram':
                return 'instagram';
            case 'facebook':
                return 'facebook';
            default:
                return undefined;
        }
    }

    const getSocialFullUrl = () => {
        switch (type) {
            case 'instagram':
                return 'https://www.instagram.com/' + username;
            case 'facebook':
                return 'https://www.facebook.com/' + username;
            default:
                return undefined;
        }
    }

    return (
        <div className="SocialLink">
            <img className="SocialLink-logo" src={getLogoImage()} alt={getSocialTypeName()} /><span className='SocialLink-marker'>-</span><a className="SocialLink-url" href={getSocialFullUrl()}>@{username}</a>
        </div>
    );
}